import React from 'react';
import { Link } from 'gatsby';
import ReactMarkdown from 'react-markdown';

const linkRender = (props) => (
  <Link
    target={props.newTab ? '_blank' : '_self'}
    rel="noreferrer"
    to={props.link}
    className="shadow-xl icon-column rounded-sm bg-gray-100"
  >
    <div className="svg-icon flex justify-center">{props.icon}</div>
    <h4 className="text-red-300 font-normal">{props.title}</h4>
    <ReactMarkdown>{props.description}</ReactMarkdown>
  </Link>
);

const colRender = (props) => (
  <div className="shadow-xl icon-column rounded-sm bg-gray-100">
    <div className="svg-icon flex justify-center">{props.icon}</div>
    <h4 className="text-red-300 font-normal">{props.title}</h4>
    <ReactMarkdown>{props.description}</ReactMarkdown>
  </div>
);
export default function Column(props) {
  return props.isCard ? colRender(props) : linkRender(props);
}
