import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import ReactMarkdown from 'react-markdown';
import { Container } from 'react-grid-system';

import Hero from '../components/rows/hero/index';
import Banner from '../images/hero-images/contact-hero.jpg';
import Follow from '../components/rows/cta/follow';
import Column from '../components/elements/column';

import Hospital from '../icons/hospital.svg';

export default function Contact({ data }) {
  return (
    <Layout>
      <Seo
        title="Contact"
        description="Connect today to ask questions, book your appointment or make a referral to PACE Cardiology with 
            locations in Barrie, Newmarket and Orillia (ECHO lab)."
      />
      <Hero title="Contact" banner={Banner} />
      {data.allStrapiContact.edges.map(({ node }) => (
        <React.Fragment key={node}>
          <Container>
            <ReactMarkdown
              className="contact-heading flex flex-col w-8/12 mx-auto text-center"
              children={node.contact1}
              linkTarget="_blank"
              rel="noreferrer"
            />
          </Container>
          <div className="bg-gray-100 py-52 my-52 medical-bg">
            <Container className="flex flex-wrap justify-between">
              <Column
                icon={<Hospital />}
                title={node.contact2.col1_title}
                description={node.contact2.col1_description}
                isCard
              />
              <Column
                icon={<Hospital />}
                title={node.contact2.col2_title}
                description={node.contact2.col2_description}
                isCard
              />
              <Column
                icon={<Hospital />}
                title={node.contact2.col3_title}
                description={node.contact2.col3_description}
                isCard
              />
            </Container>
          </div>
        </React.Fragment>
      ))}
      <Follow />
    </Layout>
  );
}

export const query = graphql`
  query Contact {
    allStrapiContact {
      edges {
        node {
          id # id of the node
          contact1
          contact2 {
            col1_title
            col2_title
            col3_title
            col1_description
            col2_description
            col3_description
          }
        }
      }
    }
  }
`;
